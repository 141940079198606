import React from 'react';
import styled from 'styled-components';
import { NavOnlyTemplate } from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import {
  addCountryCode,
  cleanDisplayPhoneNumber,
  getPhoneNumber
} from '@beelineloans/cx-library/src/utils/helpers';
import { TextAndImage } from '@beelineloans/cx-library/src/components/layout/panels';
// import ContactForm from '@beelineloans/cx-library/src/components/forms/ContactForm';
import { H3 } from '@beelineloans/cx-library/src/components/typography';
import Hero from '@beelineloans/cx-library/src/images/contact-hero.png';
import Facebook from '@beelineloans/cx-library/src/images/icons/svg/facebook_logo.svg';
import Twitter from '@beelineloans/cx-library/src/images/icons/svg/twitter_logo.svg';
import LinkedIn from '@beelineloans/cx-library/src/images/icons/svg/linkedin_logo.svg';
import Instagram from '@beelineloans/cx-library/src/images/icons/svg/instagram_logo.svg';
import { ButtonEmail } from '@beelineloans/cx-library/src/components/buttons';
import { ButtonText } from '../../../shared/GatsbyButton';

const Social = styled.div`
	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		padding-left: 160px;
	}
`;

const Wrap = styled.section`
	display: flex;
	flex-direction: column;

	@media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
		flex-direction: row;
	}
`;

const List = styled.ul`
	list-style: none;
	padding: 0;
`;

const ListItem = styled.li`
	margin-bottom: 18px;
	padding-left: 0;
	background-repeat: no-repeat;
	background-size: 25px 25px;
	background-position: left 1px center;
	line-height: 27px;

  a {
    padding-left: 47px;
  }
`;

const ListFacebook = styled(ListItem)`
	background-image: url(${Facebook});
`;

const ListInstagram = styled(ListItem)`
  background-image: url(${Instagram});
`;

const ListTwitter = styled(ListItem)`
	background-image: url(${Twitter});
`;

const ListLinkedIn = styled(ListItem)`
	background-image: url(${LinkedIn});
`;

const LinkH3 = styled(H3)`
	padding-top: 30px;
`;

const TextAndImageStyled = styled(TextAndImage)`
  .image-wrap img { 
    padding-top: 50px;
  }

  @media only screen and (${props => props.theme.mediaBreakpoints.tablet}) {
    .image-wrap img { padding-top: 0}
  }

`;

const subheadlineBlock = (
  <>
    Chat to us.<br />{CONST.BUSINESS_HOURS.LINE1}, {CONST.BUSINESS_HOURS.LINE2}
  </>
);

const Contact = () => {
  const openChat = () => {
    if (window && window.$crisp) {
      window.$crisp.push(['do', 'chat:toggle']);
    }
  };

  return (
    <NavOnlyTemplate headerProps={{ hideButton: true }}>
      <SEO
        path={CONST.LINKS.MAIN.CONTACT}
        title="How to contact us"
        description={`Give us a shout. ${CONST.BUSINESS_HOURS.LINE1}, ${CONST.BUSINESS_HOURS.LINE2} via email, SMS, chat, phone, Facebook or Twitter.`}
      />
      <TextAndImageStyled
        left
        image={{ src: Hero, mobileImageOnTop: true, desktopWidth: '100%', alignCenter: false }}
        heading={subheadlineBlock} // "We’re not all this good looking but we’re all super helpful so get in touch if you need a hand."
      >
        <Wrap>
          <div>
            {/* <LinkH3>Live chat.</LinkH3>
            <Span dark id="chatNow">
              Chat now
            </Span> */}

            <LinkH3>Chat.</LinkH3>
            <ButtonText
              selected
              dark
              onClick={openChat}
            >
              Want to chat to a Loan Guide?
            </ButtonText>

            <LinkH3>Email.</LinkH3>
            <ButtonEmail
              selected
              dark
              type="contact"
            >
              Contact us via email
            </ButtonEmail>

            <LinkH3>Phone.</LinkH3>
            <ButtonText selected to={`tel:${addCountryCode(getPhoneNumber())}`}>
              {cleanDisplayPhoneNumber(getPhoneNumber())}
            </ButtonText>
          </div>
          <Social>
            <LinkH3>Connect.</LinkH3>
            <List>
              <ListFacebook><ButtonText to={CONST.LINKS.EXTERNAL.SOCIAL.FACEBOOK} newWindow>Facebook</ButtonText></ListFacebook>
              <ListInstagram><ButtonText to={CONST.LINKS.EXTERNAL.SOCIAL.INSTAGRAM} newWindow>Instagram</ButtonText></ListInstagram>
              <ListTwitter><ButtonText to={CONST.LINKS.EXTERNAL.SOCIAL.TWITTER} newWindow>Twitter</ButtonText></ListTwitter>
              <ListLinkedIn><ButtonText to={CONST.LINKS.EXTERNAL.SOCIAL.LINKEDIN} newWindow>LinkedIn</ButtonText></ListLinkedIn>
            </List>
          </Social>
        </Wrap>
      </TextAndImageStyled>
      {/* <ContactForm
      title="Get in touch."
      contactType={CONST.CONTACT_TYPES.CONTACT}
    /> */}
    </NavOnlyTemplate>
  );
};

export default Contact;
